/* eslint-disable react-hooks/exhaustive-deps */

// import "./styles.scss";

import React, { useState, useEffect } from "react";
import { shuffle } from "lodash";
import { StaticQuery, graphql } from "gatsby";

const HomeClientsDynamic = ({ homeClientsData }) => {
	// const images = [
	// 	require("../assets/img/clients-home/logo-home-gmr.png"),
	// 	require("../assets/img/clients-home/logo-home-greenpoint.png"),
	// 	require("../assets/img/clients-home/logo-home-imperial.png"),
	// 	require("../assets/img/clients-home/logo-home-komputronik.png"),
	// 	require("../assets/img/clients-home/logo-home-krakow.png"),
	// 	require("../assets/img/clients-home/logo-home-steelseries.png"),
	// 	require("../assets/img/clients-home/logo-home-techland.png"),
	// 	require("../assets/img/clients-home/logo-home-thailand.png"),
	// 	require("../assets/img/clients-home/logo-home-bmr.png"),
	// 	require("../assets/img/clients-home/logo-home-bialaperla.png"),
	// 	require("../assets/img/clients-home/logo-home-dombud.png"),
	// 	require("../assets/img/clients-home/logo-home-empik.png"),
	// 	require("../assets/img/clients-home/logo-home-ibis.png"),
	// 	require("../assets/img/clients-home/logo-home-mediaarena.png"),
	// 	require("../assets/img/clients-home/logo-home-pigment.png"),
	// 	require("../assets/img/clients-home/logo-home-speakup.png"),
	// ];

	const [data, setData] = useState(homeClientsData.nodes[2].acf.home_clients);
	const [isVisible, setIsVisible] = useState(true);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsVisible(false);

			setTimeout(function () {
				setData(shuffle(homeClientsData.nodes[2].acf.home_clients));
			}, 250);

			setTimeout(function () {
				setIsVisible(true);
			}, 500);
		}, 8000);
		return () => clearInterval(interval);
	}, [homeClientsData.nodes[0].acf.home_clients]);

	return (
		homeClientsData?.nodes[2]?.acf?.home_clients?.length > 0 && (
			<div className="custom_row_wrapper">
				<div className="row custom_row">
					{data.slice(0, 8).map((item, index) => (
						<div className="col-md-3 col-6" key={index}>
							<div
								className={`home-clients__image${
									isVisible
										? " home-clients__image--visible"
										: ""
								}`}
							>
								<img src={item} alt="" />
							</div>
						</div>
					))}
				</div>
			</div>
		)
	);
};

export default () => (
	<StaticQuery
		query={graphql`
			{
				homeClientsData: allWordpressPage {
					nodes {
						acf {
							home_clients
						}
					}
				}
			}
		`}
		render={(data) => <HomeClientsDynamic {...data} />}
	/>
);
